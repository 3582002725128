<template>
  <div class="container">
    <div class="contacts__wrapper">
      <div class="contacts__list">
        <div class="table__titles">
          <div class="table__title"></div>
          <div class="table__title">Імʼя</div>
          <div class="table__title">Компанія</div>
          <div class="table__title">Посада</div>
          <div class="table__title">Роль</div>
          <div class="table__title">Дата народження</div>
          <div class="table__title">Телефон</div>
          <div class="table__title"></div>
        </div>
        <div v-for="(contact, i) of contacts" :key="i" class="contacts__item" :class="{ 'contacts__item--golden': contact.isGolden }" @click="contactInfo(contact)">
          <div class="contacts__letter">{{ contact.name[0] }}</div>
          <div class="contacts__name">{{ contact.name }}</div>
          <div class="">{{ contact.company ? '\"' + contact.company.name + '\"' : "" }}</div>
          <div class="">{{ contact.jobPosition && contact.jobPosition.name }}</div>
          <div class="contacts__role">{{ contact.roles.map(x => x.name).join(' / ') }}</div>
          <div class="contacts__birthday">
            <i class="ri-calendar-event-fill"></i>
            <span v-if="contact.birthday">{{ contact.birthday.replaceAll('-', '.') }}</span>
          </div>
          <div class="contacts__phone">
            <span>{{ contact.phones[0].phone }}</span>
          </div>
          <a :href="'tel:' + format(contact.phones[0].phone)" @click.stop><i class="ri-phone-fill"></i></a>
          <!-- {{contact}} -->
          <!-- <a :href="'viber://chat?number=%2B' + format(contact.phones[0].phone)" @click.stop><i class="ri-phone-fill"></i></a> -->
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadContacts"></infinite-loading>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContactInfo from './ContactInfo'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Contacts',
  data: () => ({
    isLoading: false
  }),
  components: {
    InfiniteLoading
  },
  computed: {
    ...mapState({
      contacts: s => s.contacts.contacts,
      totalCount: s => s.contacts.pagination.total,
      page: s => s.contacts.page,
    }),
    hasMoreResults() {
      return this.contacts.length < this.totalCount && this.page > 1
    },
  },
  methods: {
    contactInfo(contact) {
      this.$modal.display(ContactInfo, {contact}, {name: 'ModalContactInfo'})
    },
    async loadContacts() {
      this.isLoading = true
      try {
        await this.$store.dispatch('contacts/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    format(phone) {
      return phone.slice(1, 4) + phone.slice(6, 9) + phone.slice(10, 14)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
