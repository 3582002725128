var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "contacts__wrapper" }, [
        _c(
          "div",
          { staticClass: "contacts__list" },
          [
            _vm._m(0),
            _vm._l(_vm.contacts, function(contact, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "contacts__item",
                  class: { "contacts__item--golden": contact.isGolden },
                  on: {
                    click: function($event) {
                      return _vm.contactInfo(contact)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "contacts__letter" }, [
                    _vm._v(_vm._s(contact.name[0]))
                  ]),
                  _c("div", { staticClass: "contacts__name" }, [
                    _vm._v(_vm._s(contact.name))
                  ]),
                  _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        contact.company ? '"' + contact.company.name + '"' : ""
                      )
                    )
                  ]),
                  _c("div", {}, [
                    _vm._v(
                      _vm._s(contact.jobPosition && contact.jobPosition.name)
                    )
                  ]),
                  _c("div", { staticClass: "contacts__role" }, [
                    _vm._v(
                      _vm._s(
                        contact.roles
                          .map(function(x) {
                            return x.name
                          })
                          .join(" / ")
                      )
                    )
                  ]),
                  _c("div", { staticClass: "contacts__birthday" }, [
                    _c("i", { staticClass: "ri-calendar-event-fill" }),
                    contact.birthday
                      ? _c("span", [
                          _vm._v(_vm._s(contact.birthday.replaceAll("-", ".")))
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "contacts__phone" }, [
                    _c("span", [_vm._v(_vm._s(contact.phones[0].phone))])
                  ]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "tel:" + _vm.format(contact.phones[0].phone)
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [_c("i", { staticClass: "ri-phone-fill" })]
                  )
                ]
              )
            })
          ],
          2
        )
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.loadContacts } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table__titles" }, [
      _c("div", { staticClass: "table__title" }),
      _c("div", { staticClass: "table__title" }, [_vm._v("Імʼя")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Компанія")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Посада")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Роль")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Дата народження")]),
      _c("div", { staticClass: "table__title" }, [_vm._v("Телефон")]),
      _c("div", { staticClass: "table__title" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }