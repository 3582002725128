var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contactInfo__wrapper" }, [
    _c("div", { staticClass: "contactInfo__header" }, [
      _c("div", { staticClass: "header__left" }, [
        _vm._v(" Контактна Інформація ")
      ]),
      _c("div", { staticClass: "header__right" }, [
        _vm.isAdmin
          ? _c(
              "button",
              {
                staticClass: "button__icon button__icon--red",
                on: { click: _vm.delContact }
              },
              [_c("i", { staticClass: "ri-delete-bin-line" })]
            )
          : _vm._e(),
        _vm.isContactEditable
          ? _c(
              "button",
              { staticClass: "button__icon", on: { click: _vm.editContact } },
              [_c("i", { staticClass: "ri-pencil-fill" })]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button__icon button--onmobile",
            on: {
              click: function($event) {
                return _vm.$modal.hide("ModalContactInfo")
              }
            }
          },
          [_c("i", { staticClass: "ri-close-fill" })]
        )
      ])
    ]),
    _c("div", { staticClass: "contactInfo__main" }, [
      _c("div", { staticClass: "main__header" }, [
        _c("div", { staticClass: "main__letter letter" }, [
          _vm._v(_vm._s(_vm.contact.name[0]))
        ]),
        _c("div", { staticClass: "main__title" }, [
          _c("div", { staticClass: "main__name" }, [
            _vm._v(_vm._s(_vm.contact.name))
          ]),
          _c("div", { staticClass: "main__role" }, [
            _vm._v(
              "Роль: " +
                _vm._s(
                  _vm.contact.roles
                    .map(function(x) {
                      return x.name
                    })
                    .join(" / ")
                )
            )
          ]),
          _vm.contact.birthday
            ? _c("div", { staticClass: "main__birthday" }, [
                _vm._v(
                  "Дата народження: " +
                    _vm._s(_vm.contact.birthday.replaceAll("-", "."))
                )
              ])
            : _vm._e(),
          _vm.contact.company
            ? _c("div", { staticClass: "main__birthday" }, [
                _vm._v(
                  ' Компанія: "' + _vm._s(_vm.contact.company.name) + '" '
                ),
                _vm.contact.jobPosition
                  ? _c("span", [
                      _vm._v("(" + _vm._s(_vm.contact.jobPosition.name) + ")")
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "main__content" },
        _vm._l(_vm.contact.phones, function(phone) {
          return _c("div", { key: phone.id, staticClass: "main__contact" }, [
            _c(
              "a",
              {
                attrs: { href: "tel:" + phone.phone },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [_c("i", { staticClass: "ri-phone-fill" })]
            ),
            _c("div", { staticClass: "main__phone" }, [
              _vm._v(_vm._s(phone.phone))
            ]),
            _c("div", { staticClass: "main__phone-box" }, [
              _c(
                "button",
                {
                  staticClass: "button__circle",
                  class: { "button__circle--blue": phone.isOnViber },
                  on: {
                    click: function($event) {
                      return _vm.sendMessage(
                        Object.assign({}, phone, { message: "viber" })
                      )
                    }
                  }
                },
                [_c("i", { staticClass: "i-viber" })]
              ),
              _c(
                "button",
                {
                  staticClass: "button__circle",
                  class: { "button__circle--blue": phone.isOnTelegram }
                },
                [_c("i", { staticClass: "i-telegram" })]
              ),
              _c(
                "button",
                {
                  staticClass: "button__circle",
                  class: { "button__circle--blue": phone.isOnWhatsapp }
                },
                [_c("i", { staticClass: "i-whatsapp" })]
              ),
              _c(
                "button",
                {
                  staticClass: "button__circle",
                  class: { "button__circle--blue": phone.isOnFacebook }
                },
                [_c("i", { staticClass: "i-messanger" })]
              )
            ])
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "main__rel rel" },
        _vm._l(_vm.relations, function(rel, i) {
          return _c("Relation", { key: i, attrs: { relation: rel } })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }