<template>
  <div class="contactInfo__wrapper">
    <div class="contactInfo__header">
      <div class="header__left">
        Контактна Інформація
      </div>
      <div class="header__right">
        <button v-if="isAdmin" class="button__icon button__icon--red" @click="delContact">
          <i class="ri-delete-bin-line"></i>
        </button>
        <button v-if="isContactEditable" class="button__icon" @click="editContact">
          <i class="ri-pencil-fill"></i>
        </button>
        <button class="button__icon button--onmobile" @click="$modal.hide('ModalContactInfo')">
          <i class="ri-close-fill"></i>
        </button>
      </div>
    </div>
    <div class="contactInfo__main">
      <div class="main__header">
        <div class="main__letter letter">{{ contact.name[0] }}</div>
        <div class="main__title">
          <div class="main__name">{{ contact.name }}</div>
          <div class="main__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
          <div v-if="contact.birthday" class="main__birthday">Дата народження: {{ contact.birthday.replaceAll('-', '.') }}</div>
          <div v-if="contact.company" class="main__birthday">
            Компанія: "{{ contact.company.name }}"
            <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
          </div>
        </div>
      </div>
      <div class="main__content">
        <div v-for="phone in contact.phones" :key="phone.id" class="main__contact">
          <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
          <div class="main__phone">{{ phone.phone }}</div>
          <div class="main__phone-box">
            <button
              class="button__circle"
              :class="{'button__circle--blue': phone.isOnViber}"
              @click="sendMessage({...phone, message: 'viber'})"
            >
              <i class="i-viber"></i>
            </button>
            <button class="button__circle" :class="{'button__circle--blue': phone.isOnTelegram}">
              <i class="i-telegram"></i>
            </button>

            <button class="button__circle" :class="{'button__circle--blue': phone.isOnWhatsapp}">
              <i class="i-whatsapp"></i>
            </button>
            <button class="button__circle" :class="{'button__circle--blue': phone.isOnFacebook}">
              <i class="i-messanger"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="main__rel rel">
        <Relation v-for="(rel, i) in relations" :key="i" :relation="rel" />
      </div>
    </div>
  </div>
</template>

<script>
import EditContact from '../EditContact'
import Relation from '@/components/Relation'
import moment from 'moment'
import localConstants from '@/utils/localConstants'

export default {
  name: 'contactInfo',
  props: ['contact'],
  components: {
    Relation
  },
  data() {
    return {
      moment,
      localConstants,
    }
  },
  async created() {
    await this.$store.dispatch('contacts/fetchRelation', this.contact.id)
  },
  computed: {
    isAdmin() {
      return this.$store.getters.user.role === 4
    },
    isContactEditable() {
      return this.isAdmin || this.contact?.roles.every(x => x.id === this.localConstants.CONTACT_ROLES.AGENCY)
    },
    relations() {
      return this.$store.getters['contacts/relation']
    }
  },
  methods: {
    editContact() {
      this.$modal.hide('ModalContactInfo')
      this.$modal.display(EditContact, {contact: this.contact}, {name: 'ModalEditContact'})
    },
    async delContact() {
      await this.$store.dispatch('contacts/delete', this.contact.id)
      this.$modal.hide('ModalContactInfo')
      this.$store.commit('contacts/reset')
      this.$store.dispatch('contacts/fetch')
    },
    sendMessage(phone) {
      const number = phone.phone.slice(1, 4) + phone.phone.slice(6, 9) + phone.phone.slice(10, 14)
      switch (phone.message) {
        case 'viber':
          if (phone.isOnViber) document.location.href = `viber://chat?number=${number}`
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
